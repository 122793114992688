const dataClientes=[
  {
      cliente:"Ecopetrol",
      img:"ecopetrol.png"
  }, 
  {
      cliente:"Argos",
      img:"argos.jpg"
  },
  {
      cliente:"Ingeominas, Instituto Colombiano de Geología y Minería",
      img:"ingeominas.jpg"
  }, 
  {
      cliente:"upme, Unidad de Planeación Minero Energética",
      img:"upme.jpg"
  },
  {
      cliente:"Minesterio de Minas y energía",
      img:"minenergia.png"
  },
  {
      cliente:"Cundinamarca, corazón de Colombia",
      img:"cundinamarca_corazon_colombia.jpg"
  },
  {
      cliente:"Gobernación de Antioquia",
      img:"gobernacion_antioquia.jpg"
  },
  {
      cliente:"OXY",
      img:"oxy.jpg"
  },
  {
      cliente:"MINERCOL",
      img:"minercol.jpg"
  },
]

export default dataClientes;