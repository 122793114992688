import SVG_HONDAS from '../../svg/SVG_HONDAS';

const CajaAzul = (props) => {
  const { data, descripcion, titulo, id } = props;
  return (
    <>
      <SVG_HONDAS fill2='#093959' fill1="#F19F1F" />
      <div className="pb-10 bg-principal-azul caja-ondas">
        <div id={id}  className="container mx-auto">
          <div className="w-11/12 mx-auto">
            <h3 className="titulo-subsecciones">{titulo}</h3>
            <p className='descripcion'>{descripcion}</p>
            <div className="flex flex-wrap justify-end justify-center mt-12">
              {
                !!data && data.lenght !== 0 && data.map((servicio, index) =>
                  <div className="md:basis-1/2 lg:basis-4/12 flex servicio pb-10 lg:pb-0 lg:mb-16" key={`servicio${servicio.nombre}${index}`}>
                    <div className='basis-10/12 text-center mx-auto'>
                      <span className={`text-100 icon-${servicio.clase}`}></span>
                      <div className='mt-5'>
                        {!!servicio.nombre && <p className='mb-3 text-20 opensans-700 uppercase'>{servicio.nombre}</p>}
                        <p className='opensans-300 text-17'>{servicio.descripcionCorta}</p>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CajaAzul