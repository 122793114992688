import React from "react";
const argos = {
  proyecto1: {
    nombre: 'Gestión de Títulos Mineros',
    nombreCorto: 'GTM',
    cliente: 'Argos',
    imagen: '/argos/gtm/ficha.png',
    sector: 'Minería',
    descripcion: (() => (
      <>
       <p>Facilita la <span className="texto-resaltado">administración de títulos mineros</span> acorde con la regulación que aplique. Registra los <span className="texto-resaltado">eventos</span> mineros y ambientales con su respectivo <span className="texto-resaltado"> soporte documental;</span> genera <span className="texto-resaltado">reporte automático</span> de alarmas, con periodicidad definida, al responsable de atender las <span className="texto-resaltado"> obligaciones</span>. Contiene <span className="texto-resaltado">visor geográfico</span> con cartografía básica, imágenes de satélite, <span className="texto-resaltado">información minera, ambiental y predial</span>; administrada en base de datos geográfica corporativa <span className="texto-resaltado">(GDB)</span>. Vínculo en tiempo real al servicio geográfico de Anna Minería.</p> 
      </>
    ))(),
  },
  proyecto2: {
    nombre: 'Módulo de minería',
    nombreCorto: 'Módulo de minería',
    cliente: 'Argos',
    imagen: '/argos/mineria/ficha.png',
    sector: 'Minería',
    descripcion: (() => (
      <>
       <p><span className="texto-resaltado">Visor geográfico</span> que permite ver la información de: <span className="texto-resaltado">restricciones</span> ambientales y territoriales, áreas licenciadas ambientalmente, infraestructura, <span className="texto-resaltado">planeamiento e intervenciones mineras</span>, con el fin de facilitar el seguimiento de actividades en el título minero y visualizar la <span className="texto-resaltado">evolución</span> de las áreas en <span className="texto-resaltado">diferentes períodos de tiempo</span>. </p> 
      </>
    ))(),
  },
}

export default argos;