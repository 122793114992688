import { useEffect, useRef, useState } from "react";

const DropDown = (props) => {
  const { acordeonActivo, actualizarAcordeonActivo, dato, index, isMarcado, isServicio, seccion } = props;

  const [heightDropDown, setHeightDropDown] = useState(0);

  const refAcordeon = useRef(null);

  useEffect(() => {
    actualizarAltura()
    window.addEventListener('resize', actualizarAltura);
    return () => {
      window.removeEventListener('resize', actualizarAltura);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acordeonActivo])


  const actualizarAltura = () => {
    const alturaDrop = refAcordeon.current.scrollHeight;
    const newAltura = acordeonActivo === index ? alturaDrop : 0;

    setHeightDropDown(newAltura, isServicio);
  }

  const fnActivarAcordeon = (index) => {
    const valorAcordeonActivo = index === acordeonActivo ? null : index;
    actualizarAcordeonActivo(valorAcordeonActivo, isServicio);
  }


  return (
    <div className='dropdown flex flex-col'>
      <button
        className='flex justify-between boton-dropdown items-center'
        onClick={() => fnActivarAcordeon(index)}
      >
        <p><span className={`mr-4 icon-${dato.clase} icono-seccion`}></span> {dato.nombre}</p>
        <span className={`icon-${acordeonActivo === index ? 'menos' : 'mas'}`}></span>

      </button>
      <div
        className={`informacion-seccion ${acordeonActivo === index ? 'border-t-2' : ''}`}
        ref={refAcordeon}
        style={{ height: heightDropDown }}
      >
        <div>
          {
            isMarcado
              ? dato.contenido
              : dato.informacion.map((parrafo, index) =><p key={`${seccion}${dato.clase}${index}`}>{parrafo}</p>)
          }
        </div>
      </div>
    </div>
  )
}

export default DropDown;