import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from 'swiper';
import dataClientes from './dataClientes';



const Clientes = () => {
  return (
    <div className="clientes bg-gris-claro pt-10 pb-20 px-10 lg:px-0">
      <div className="container m-auto">
        <h3 className="titulo-subsecciones">Clientes</h3>
        <p>Conozca <span className="resaltados">todo lo que podemos hacer</span> por <span className="resaltados">usted</span> y para <span className="resaltados">su empresa</span>.</p>

        <Swiper
          autoplay={{
            delay: 2000
          }}
          breakpoints={{
            526: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 5,
            },
            1200: {
              slidesPerView: 6,
            },
          }}
          className="mt-10 flex mb-5"
          loop
          modules={[Pagination, Autoplay, Navigation]}
          slidesPerView={1}
        >
          {
            dataClientes.map(cliente => (
              <SwiperSlide
                key={cliente.cliente}
                className="xl:2/12 cliente"
              >
                <div className="flex justify-between items-center mx-5">
                  <img src={require(`../../../img/logos/clientes/${cliente.img}`)} alt={cliente.cliente} />
                </div>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>



    </div>
  )
}

export default Clientes;