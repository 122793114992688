import React from "react";
const antioquia = {
  proyecto1: {
    nombre: 'Solicitudes mineras',
    nombreCorto: 'Solicitudes mineras',
    cliente: 'Gobernación de Antioquia',
    imagen: '/antioquia/ficha.png',
    sector: 'Mineria',
    descripcion: (() => (
      <>
        <p><span className="texto-resaltado">Funcionalidad semiautomática</span> que permite <span className="texto-resaltado">analizar</span> las solicitudes de <span className="texto-resaltado">áreas de concesión minera</span> y determinar el área susceptible a otorgar. Realiza <span className="texto-resaltado">superposición</span> de áreas, <span className="texto-resaltado">recorte automático</span> validando primero en el tiempo, primero en el derecho teniendo en cuenta la ley de la media hora, la cual consiste en que las solicitudes que llegan entre la hora en punto y la media hora siguiente no se hace recorte, lo mismo que desde la hora y media hasta la siguiente hora en punto.</p> 
      </>
    ))(),
  },
}

export default antioquia;