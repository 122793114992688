import dataRecursoHumano from "./../../data/dataRecursoHumano";

const RecursoHumano = () => {
  return (
    <div className="container mx-auto pt-8 lg:pt-16 pb-20 recurso-humano px-8 lg:px-0">
      <h3 className="titulo-subsecciones">Recurso humano</h3>
      <div className="flex flex-col flex-wrap lg:flex-nowrap lg:flex-row py-5 lg:py-10 info-nosotros justify-between flex-row">
        <div className="basis-6/12">
          <img
            className="foto-redondeada mb-5 lg:mb-0"
            src={require("../../img/nosotros/edificio.png")}
            alt="foto de equipo"
          />
        </div>
        <div className="basis-6/12 lg:pl-16">
          <p> Contamos con personal altamente calificado y con experiencia en desarrollo de aplicaciones, bases de datos alfanuméricas y geográficas, estructuración de capas geográficas, desarrollo de funcionalidades de captura de datos en campo, visores geográficos, tableros de control, entre otros:</p>
          <div className="mt-5">
            {dataRecursoHumano.map((dato, index) => (
              <div key={`dropdown${index}`}>
                <div className='dropdown flex flex-col'>
                  <div
                    className='flex justify-between boton-dropdown items-center'
                  >
                    <p><span className={`mr-4 icon-${dato.clase} icono-seccion`}></span> {dato.nombre}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecursoHumano;
