import TituloSeccion from "../generales/TituloSeccion"
import { useTrackedState } from '../../store';
import FichaProyecto2 from './FichaProyecto2';

const Proyectos = () => {

  const state = useTrackedState()
  const { arrayProyectos } = state;


  return (
    <>
      <TituloSeccion titulo='Proyectos' />

      <div className="container mx-auto py-20">
        <div className="flex flex-wrap pb-5">
          {
            !!arrayProyectos && arrayProyectos.length !== 0 && arrayProyectos.map(proyecto => {
              return (
                <FichaProyecto2
                  proyecto={proyecto}
                  key={proyecto.id}
                />
              )
            })
          }
        </div>
      </div >
    </>
  )
}

export default Proyectos