import { useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contacto = () => {

  const [nombre, setNombre] = useState(true);
  const [correo, setCorreo] = useState(true);
  const [telefono, setTelefono] = useState(true);
  const [comentarios, setComentarios] = useState(true);
  const [isLoading, setIsLoading] = useState(false);


  const refFormulario = useRef(null);
  const refNombre = useRef(null);
  const refCorreo = useRef(null);
  const refTelefono = useRef(null);
  const refComentarios = useRef(null);

  const enviarFormulario = async (e) => {
    const url = window.location.href;
    
    const configuracionNotificacion = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored'
    };

    if (refFormulario.current.reportValidity()) {
      e.preventDefault();
      setIsLoading(true);

      if (url.search('https://') !== -1) {
        var formdata = new FormData();
        formdata.append("nombre", refNombre.current.value);
        formdata.append("correo", refCorreo.current.value);
        formdata.append("telefono", refTelefono.current.value);
        formdata.append("comentarios", refComentarios.current.value);

        if (isLoading === false) {
          await fetch('https://www.inascol.com/enviarCorreo.php', {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
          }).then((response) => {
            console.log(response);
            refNombre.current.value = '';
            refCorreo.current.value = '';
            refTelefono.current.value = '';
            refComentarios.current.value = '';

            toast.success("El correo se ha enviado con éxito!", configuracionNotificacion);
          })
            .catch((error) => {
              console.error('Error:', error);
              toast.error("Lo sentimos! El formulario no se ha enviado, por favor intentalo nuevamente.", configuracionNotificacion);
            });
          setIsLoading(false);
        }
      } else {
        toast.error("Lo sentimos! El formulario no se ha enviado, por favor intentalo nuevamente.", configuracionNotificacion);
        setIsLoading(false);
      }

    } else {
      setNombre(refNombre.current.reportValidity());
      setCorreo(refCorreo.current.reportValidity());
      setTelefono(refTelefono.current.reportValidity());
      setComentarios(refComentarios.current.reportValidity());
    }
  }


  const validarCampo = (e) => {
    const option = e.target.name;
    const validacion = e.target.reportValidity();

    switch (option) {
      case 'nombre': setNombre(validacion);
        break;
      case 'correo': setCorreo(validacion);
        break;
      case 'telefono': setTelefono(validacion);
        break;
      case 'comentarios': setComentarios(validacion);
        break;
      default:
    }
  }


  return (
    <>
      <div className="contacto pb-10 lg:pb-20 px-5 lg:px-0">
        <div className="mascara-contacto"></div>
        <div className="mascara-contacto mascara-contacto-2"></div>

        <div className="container mx-auto flex flex-col lg:flex-row py-20 justify-between">
          <div className="lg:basis-6/12">
            <form className="formulario-contacto" ref={refFormulario} onSubmit={enviarFormulario}>
              <div className="info-formulario">
                <h3>Contáctenos</h3>
                <p>Envíenos su comentario o inquietud y nuestro equipo lo contactará en el menor tiempo posible.</p>
              </div>
              <div className="campos-formulario">
                <div className="campo-formulario">
                  <label>Nombre <span>*</span></label>
                  <input
                    className={`${!nombre ? 'error-input' : ''}`}
                    name='nombre'
                    onChange={validarCampo}
                    placeholder="Ingresa tu nombre"
                    ref={refNombre}
                    required
                    type='text'
                  />
                  {!nombre && <p className="text-input-advertencia">Este campo es obligario, por favor ingresa tu nombre.</p>}
                </div>
                <div className="campo-formulario">
                  <label>Correo electrónico <span>*</span></label>
                  <input
                    className={`${!correo ? 'error-input' : ''}`}
                    name='correo'
                    onChange={validarCampo}
                    placeholder="Ingresa tu correo"
                    ref={refCorreo}
                    required
                    type='email'
                  />
                  {!correo && <p className="text-input-advertencia">Este campo es obligario, por favor ingresa tu correo.</p>}
                </div>
                <div className="campo-formulario">
                  <label>Télefono <span>*</span></label>
                  <input
                    className={`${!telefono ? 'error-input' : ''}`}
                    name='telefono'
                    onChange={validarCampo}
                    placeholder="Ingresa tu número de contacto"
                    ref={refTelefono}
                    required
                    type='tel'
                  />
                  {!telefono && <p className="text-input-advertencia">Este campo es obligario, por favor ingresa tu número de contacto.</p>}
                </div>
                <div className="campo-formulario">
                  <label>Comentarios <span>*</span></label>
                  <textarea
                    className={`${!comentarios ? 'error-input' : ''}`}
                    name='comentarios'
                    onChange={validarCampo}
                    placeholder="Dejanos tu comentario"
                    ref={refComentarios}
                    required
                    rows={5}
                  />
                  {!comentarios && <p className="text-input-advertencia">Este campo es obligario, por favor ingresa que quieres saber.</p>}
                </div>
              </div>
              <button className="boton-borde boton-enviar" type="submit">
                {isLoading ? 'Enviando' : 'Enviar'}
              </button>
            </form>
          </div>


          <div className=" basis-full lg:basis-7/12 lg:pl-20 flex flex-col md:flex-row lg:flex-col md:justify-between lg:justify-center items-center lg:items-center">
            <div className='mapa mapa-contacto flex flex items-center justify-center'>
              <div className='mapa-borde flex items-center justify-center' />
              <iframe src="https://www.arcgis.com/home/webmap/embedViewer.html?webmap=03e65cca54c640f89a10d1dcd5af17f4&amp;extent=-74.0701,4.6218,-74.0648,4.6255" width="100" height="100" allowFullScreen="" loading="lazy" title='mapa-inascol'></iframe>
            </div>

            <div className='info-contacto'>
              <div className="flex items-center mt-2">
                <span className="icon-ubicacion mt-0.5 mr-3"></span>
                <p className="break-words">Cra 13 No. 35 - 43 Of. 701</p>
              </div>
              <div className="flex items-center mt-2">
                <span className="icon-correo mt-0.5 mr-3"></span>
                <a className="break-words" href="mailto:inascol@inascol.com">inascol@inascol.com</a>
              </div>
              <div className="flex items-center mt-2">
                <span className="icon-telefono mt-0.5 mr-3"></span>
                <a className="break-words" href="tel:6013405407">(57) + 60 + 1 + 3405407</a>
              </div>
              <div className="flex items-center mt-2">
                <span className="icon-celular mt-0.5 mr-3"></span>
                <a className="break-words" href="tel:3108163032">310 816 3032</a>
              </div>
            </div>

          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}

export default Contacto;