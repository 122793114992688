import { Route, Switch } from "react-router-dom";
import ServiciosPrincipal from "./ServiciosPrincipal";

const Servicios = () => {
  return (
    <>
      <Switch>
        <Route exact path='/servicios' component={ServiciosPrincipal} />
      </Switch>
    </>

  )
}

export default Servicios;