import { useEffect, useRef, useState } from "react";
import AnimatedNumber from "react-animated-number";

const NuestroTrabajo = (props) => {
  const { titulo } = props;

  const [isOnScreen, setIsOnScreen] = useState(false);
  const [numExperiencia, setNumExperiencia] = useState(0);
  const [numProyectos, setNumProyectos] = useState(0);
  const [numClientes, setNumClientes] = useState(0);

  const nuestroTrabajoRef = useRef(null);

  useEffect(() => {
    if (!!nuestroTrabajoRef.current) {
      const animacionCifras = () => {
        const topContainer = nuestroTrabajoRef.current.getBoundingClientRect().top;
        const middleScreen = window.innerHeight / 2;

        if (middleScreen >= topContainer) {
          setIsOnScreen(true);
        };
      };

      animacionCifras();
      window.addEventListener('scroll', animacionCifras);

      return () => {
        window.removeEventListener('scroll', animacionCifras);
      };
    }
  }, [nuestroTrabajoRef]);

  useEffect(() => {
    if (isOnScreen === true) {
      animacionCifras();
    }
  }, [isOnScreen]);


  const animacionCifras = () => {
    const experiencia = 20;
    const proyectos = 64;
    const clientes = 24;

    setNumExperiencia(experiencia)
    setNumProyectos(proyectos)
    setNumClientes(clientes)
  }


  return (
    <div className="bg-gris-claro py-14 nuestro-trabajo" ref={nuestroTrabajoRef}>
      <div className="container mx-auto">
        <div className="w-12/12 mx-auto">
          {!!titulo && <h3 className="titulo-subsecciones mb-8">{titulo}</h3>}

          <div className="flex flex-col md:flex-row justify-center lg:items-end mx-auto w-[70%] md:w-[100%]">
            <div className="basis:8/12 md:basis-1/2 lg:basis-3/12 flex cifra">
              <div className="basis-5/12 flex">
                <span className="icon-cake"></span>
              </div>
              <div className="basis-7/12 ml-5 flex flex-col justify-end">
                <p className="cifra-importante">
                  + {" "}
                  <AnimatedNumber
                    value={numExperiencia}
                    formatValue={n => n.toFixed(0)}
                    frameStyle={percentage => percentage > 20 && percentage < 80 ? { opacity: 0.5 } : {}}
                    duration={800}
                  />
                </p>
                <p>Años de experiencia</p>
              </div>
            </div>

            <div className="basis:8/12 md:basis-1/2 lg:basis-3/12 flex cifra">
              <div className="basis-5/12 flex">
                <span className="icon-proyectos"></span>
              </div>
              <div className="basis-7/12 ml-5 flex flex-col justify-end">
                <p className="cifra-importante">
                  + {" "}
                  <AnimatedNumber
                    value={numProyectos}
                    formatValue={n => n.toFixed(0)}
                    frameStyle={percentage => percentage > 20 && percentage < 80 ? { opacity: 0.5 } : {}}
                    duration={800}
                  />
                </p>
                <p>Proyectos realizados</p>
              </div>
            </div>

            <div className="basis:8/12 md:basis-1/2 lg:basis-3/12 flex cifra">
              <div className="basis-5/12 flex">
                <span className="icon-clientes"></span>
              </div>
              <div className="basis-7/12 ml-5 flex flex-col justify-end">
                <p className="cifra-importante">
                  + {" "}
                  <AnimatedNumber
                    value={numClientes}
                    formatValue={n => n.toFixed(0)}
                    frameStyle={percentage => percentage > 20 && percentage < 80 ? { opacity: 0.5 } : {}}
                    duration={800}
                  />
                </p>
                <p>Clientes satisfechos</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NuestroTrabajo;