import React from "react";
const upme = {
  proyecto1: {
    nombre: 'Portal Mapas',
    nombreCorto: 'Portal mapas',
    cliente: 'UPME',
    imagen: '/upme/portalmapas/ficha.png',
    sector: 'Gobierno',
    descripcion: (() => (
      <>
        <p><span className="texto-resaltado">Visores geográficos</span> de diferentes complejidades para los sectores de energía, minería, hidrocarburos y demanda. <span className="texto-resaltado">Visores especializados</span> como: alertas tempranas al generar <span className="texto-resaltado">trazado línea alta tensión</span> a menor costo y evitando pasar por capa de restricciones; cálculo del <span className="texto-resaltado">precio del carbón en boca de mina</span> para pago de regalías; establecer el <span className="texto-resaltado">distribuidor de combustible más cercano</span> a una estación de servicio, teniendo en cuenta las vías, rutas y curvas de nivel; intercambios comerciales que <span className="texto-resaltado">comparan los valores </span> de los productos importados y exportados en diferentes años; visualiza los <span className="texto-resaltado">cambios históricos </span>en una capa geográfica determinada; <span className="texto-resaltado">visor que caracteriza áreas</span> con respecto a la disponibilidad de energéticos; entre otros.
      </p> 
        </>
    ))(),
  },
proyecto2: {
    nombre: 'SIGE- SISTEMA GERENCIAL',
    nombreCorto: 'SIGE',
    cliente: 'UPME',
    imagen: '/upme/sige/ficha.png',
    sector: 'Hidrocarburos',
    descripcion: (() => (
      <>
        <p>Sistema de información gerencial que contiene módulos estratégicos de: <span className="texto-resaltado">Balance Score Card (BSC)</span> o Cuadro de Mando Integral (CMI); gestión de información; <span className="texto-resaltado">sistema de desarrollo administrativo</span>, tanto a nivel de entidad, como sectorial; <span className="texto-resaltado">control interno</span>, donde se almacena el seguimiento de la encuesta referencial del DAFP de cada entidad; seguimiento al <span className="texto-resaltado">plan de mejoramiento</span> suscrito con la Contraloría General de la República; <span className="texto-resaltado">seguimiento</span> al plan de <span className="texto-resaltado">control de calidad</span>; módulo de <span className="texto-resaltado">estadísticas y normatividad.</span></p> 
      </>
    ))(),
  }
}

export default upme;