const dataRecursoHumano = [
  {
    clase: 'bases_datos',
    nombre: 'Bases de datos'
  },
  {
    clase: 'servicio_de_aplicaciones',
    nombre: 'Servicios de aplicaciones'
  },
  {
    clase: 'java',
    nombre: 'Tecnologías Java'
  },
  {
    clase: 'portal_server',
    nombre: 'Portal server'
  },
  {
    clase: 'patrones_diseno',
    nombre: 'Patrones de diseño'
  },
  {
    clase: 'geografica',
    nombre: 'Sistemas de información geográfica'
  },
]

export default dataRecursoHumano;