const colores = {
  azul: {
    claro: '#5881A6',
    principal: '#093959'
  },
  azulClaro: {
    claro: '#094B6B',
    principal: '#1270A0'
  },
  amarillo: {
    claro: '#F2CC10',
    principal: '#F3A01D'
  },
  amarillo2: {
    claro: '#0E1627',
    principal: '#F2B84A'
  },
  verde: {
    claro: '#BBD794',
    principal: '#95C04D'
  }
}


const dataTestimonios = [
  {
    id: "testimonio 1",
    color: colores.verde,
    nombre: "Omar García Noriega",
    redes: [
    ],
    testimonio: ['En las etapas de exploración y explotación de hidrocarburos es muy importante almacenar, visualizar y analizar la información relacionada con pozos, campos petroleros, predios, ambiental, entre otras. Inascol ha sido de gran ayuda, pues gracias a su trabajo, conocimiento y funcionalidades desarrolladas, hemos logrado evidenciar aspectos a potencializar y minimizar los tiempos de acceso a la información.'],
  },
  {
    id: "testimonio 2",
    color: colores.azul,
    nombre: "Javier O. Barón Castro",
    redes: [
      { nombre: 'linkedin', link: 'https://www.linkedin.com/in/javierbar%C3%B3ncastro/' },
    ],
    testimonio: ['El profesionalismo y seriedad en el cumplimiento de las actividades contratadas en su momento para el desarrollo del “Portal Geográfico Mapas de Cundinamarca” fueron elementos clave para catalogar como caso de éxito este proyecto desarrollado por INASCOL, una empresa que demostró también su alto nivel técnico y el compromiso de su recurso humano para satisfacer las necesidades y requerimientos del cliente.'],
  },
  {
    id: "testimonio 3",
    color: colores.amarillo,
    nombre: "Luis Guillermo Montenegro",
    redes: [
      { nombre: 'linkedin', link: 'https://www.linkedin.com/in/luis-guillermo-montenegro-motta/' },
    ],
    testimonio: ['Recomiendo al equipo de profesionales de Inascol por su compromiso y dedicación en cada uno de sus proyectos. Siempre buscan encontrar el equilibrio entre las necesidades del cliente y las diferentes alternativas, para entregar un producto útil y que facilite las labores. En nuestro caso desarrollaron visores geográficos que consultan la información de la bodega de datos por municipio, facilitando la toma de decisiones.'],
  },
  {
    id: "testimonio 4",
    color: colores.azulClaro,
    nombre: "Vicente Lozano",
    redes: [
      { nombre: 'linkedin', link: 'https://www.linkedin.com/in/vicente-lozano-118b2b1b' },
    ],
    testimonio: ['He tenido la oportunidad de trabajar con Inascol en varios proyectos, en todos los casos se obtuvieron los resultados esperados y la experiencia profesional fue satisfactoria. Inascol es una empresa seria, confiable y comprometida con el cumplimiento de los objetivos de los proyectos. Sus profesionales tienen amplia experiencia en el desarrollo de Sistemas de Información Geográfica y aportan su conocimiento para generar soluciones efectivas y útiles para los clientes.'],
  },
  {
    id: "testimonio 5",
    color: colores.amarillo2,
    nombre: "Carlos Castellanos",
    redes: [
      { nombre: 'linkedin', link: 'https://co.linkedin.com/in/carlos-arturo-castellanos-su%C3%A1rez-13586134' }
    ],
    testimonio: ['Inascol es una empresa con amplio conocimiento en el desarrollo de proyectos utilizando la metodología PMI, para el Ministerio de Minas y Energía desarrolló un gran sistema de gestión que recolecta y consolida información de las entidades adscritas al Sector Minero Energético. Se destacó por el alto sentido de responsabilidad, dando solución oportuna a los requerimientos y cumpliendo a satisfacción con los objetivos del proyecto.'],
  },
]

export default dataTestimonios;