import { useState } from 'react';
import dataNosotros from '../../data/dataNosotros';
import DropDown from '../globales/DropDown';

const Objetivos = () => {

  const [acordeonActivo, setAcordeonActivo] = useState(null);
  const actualizarAcordeonActivo = (index) => setAcordeonActivo(index);


  return (
    <div className="container mx-auto">
      <div className="px-8 lg:px-0 flex py-10 lg:py-20 info-nosotros justify-between flex flex-col flex-wrap lg:flex-nowrap lg:flex-row-reverse">
        <div className="lg:basis-6/12 mb-5 lg:mb-0">
          <img className="foto-redondeada" src={require('../../img/nosotros/edificio.png')} alt="foto de equipo" />
        </div>
        <div className="lg:basis-6/12 lg:pr-16">
          <p className='lg:text-20'>Inascol es una empresa <span className="texto-resaltado">comprometida</span> en cumplir con el alcance de cada uno de los productos establecidos con nuestros clientes, en el tiempo definido y con la calidad esperada. Buscamos ser un <span className="texto-resaltado">aliado estratégico</span>  para nuestros clientes en pro de entregar productos útiles que les permita facilitar el análisis y la toma de decisiones al vincular sus datos con los mapas. </p>
          <div className='mt-5'>
            {
              dataNosotros.map((dato, index) =>
                <DropDown
                  acordeonActivo={acordeonActivo}
                  actualizarAcordeonActivo={actualizarAcordeonActivo}
                  dato={dato}
                  index={index}
                  key={`ojetivos_${dato.clase}`}
                  seccion='objetivos'
                />
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Objetivos;