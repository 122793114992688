import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import Proyectos from "./Proyectos";

import normalizeString from './../../functions/normalizeString';
import dataProyectos from './../../data/dataProyectos/dataProyectos';
import { useDispatch } from '../../store';

const ProyectosPrincipal = () => {

  const dispatch = useDispatch();


  useEffect(() => {
    const todosLosProyectos = []

    for (const cliente in dataProyectos) {
      const clienteProyecto = dataProyectos[cliente];
      const cantidadProyectos = Object.keys(clienteProyecto).length;

      if (cantidadProyectos !== 0) {
        for (const key in clienteProyecto) {
          const proyecto = clienteProyecto[key];
          todosLosProyectos.push({
            ...proyecto,
            id: `${cliente}${key}`
          })
        }
      }
    }


    const objetoProyectos = {};
    todosLosProyectos.map((proyecto) => {
      const cliente = normalizeString(proyecto.cliente);
      const nombreProyecto = normalizeString(proyecto.nombre);

      return objetoProyectos[cliente] = !!objetoProyectos[cliente]
        ? { ...objetoProyectos[cliente], [nombreProyecto]: proyecto }
        : { [nombreProyecto]: proyecto }
    })

    dispatch({
      type: 'SET_DATA',
      property: 'proyectos',
      value: objetoProyectos
    })

    dispatch({
      type: 'SET_DATA',
      property: 'arrayProyectos',
      value: todosLosProyectos
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      <Switch>
        <Route exact path='/proyectos' component={Proyectos} />
      </Switch>
    </>
  )
}

export default ProyectosPrincipal