import TituloSeccion from "../generales/TituloSeccion";
import CajaAzul from "../globales/CajaAzul";
import NuestroTrabajo from './../home/NuestroTrabajo';
import dataValores from './../../data/dataValores';
import Objetivos from "./Objetivos";
import RecursoHumano from './RecursoHumano';
import SeccionDividida from "../globales/SeccionDividida";

const Nosotros = () => {
  return (
    <>
      <TituloSeccion titulo='Nosotros' />
      <div className='container mx-auto'>
        <SeccionDividida paddingLeft='pl-16'>
          <img className="foto-redondeada" src={require('../../img/nosotros/equipo.jpg')} alt="foto de equipo" />
          <p className="lg:text-28">Somos una empresa colombiana fundada en el año 2001, dedicada a la  <span className="texto-resaltado">consultoría y desarrollo de sistemas de información geográfica, con amplia experiencia en análisis, diseño, implementación, migración de datos, capacitación</span> y puesta en producción de sistemas de Información geográfica, utilizando bases de datos.</p>
        </SeccionDividida>
      </div>
      <NuestroTrabajo />
      <Objetivos />
      <CajaAzul
        data={dataValores}
        descripcion='Para nuestra compañía es de vital importancia contar con profesionales comprometidos con la excelencia al momento de desempeñar sus tareas, con un alto nivel de análisis y en permanente búsqueda de la calidad en la construcción de las soluciones que brindamos a nuestro clientes. Por lo anterior el compromiso y la confianza se convierten en nuestras grandes fortalezas.'
        titulo='Valores'
      />
      <RecursoHumano />

    </>
  )
}

export default Nosotros;