const dataValores = [
  {
    clase: 'integridad',
    nombre: "Integridad",
    descripcionCorta: "Todas las actividades se realizan bajo criterios de confidencialidad, profesionalismo y actuando dentro de principios de honestidad, lealtad y ética profesional."
  },
  {
    clase: 'lealtad',
    nombre: "Lealtad",
    descripcionCorta: "Nos caracterizamos por tener un sentimiento y actitud de compromiso, confianza, rectitud, fidelidad y respeto hacia nuestros colaboradores y clientes."
  },
  {
    clase: 'responsabilidad',
    nombre: "Responsabilidad",
    descripcionCorta: "Estamos comprometidos con nuestros clientes y desarrollamos las actividades con calidad, cumplimiento e innovación, apoyadas en el conocimiento de la tecnología y el potencial de nuestro talento humano."
  },
  {
    clase: 'pasion',
    nombre: "Pasión",
    descripcionCorta: "El amor, el entusiasmo, la energía y la forma como se agrega valor a las actividades diarias, manifiestan la pasión por la excelencia con la que nos desempeñamos."
  },
  {
    clase: 'respeto',
    nombre: "Respeto",
    descripcionCorta: "Es de gran importancia para nosotros el escuchar, entender y valorar a los demás buscando una armonía reciproca en las relaciones interpersonales, laborales y comerciales."
  },
  {
    clase: 'innovacion',
    nombre: "Innovación",
    descripcionCorta: "Suministramos soluciones informáticas con altos estándares de calidad a través de estrategias innovadoras que satisfagan las necesidades de nuestros clientes y permitan generar un aumento en su cadena de valor."
  },
  {
    clase: 'trabajo_en_equipo',
    nombre: "Trabajo en equipo",
    descripcionCorta: "Compartimos conocimientos, experiencias y esfuerzo, aportando lo mejor de cada uno y asumiendo responsabilidades para la consecución de un trabajo con calidad."
  },
  {
    clase: 'calidad',
    nombre: "Calidad",
    descripcionCorta: "Es la norma de conducta cotidiana con la que todos los miembros de la organización estamos comprometidos, ofreciendo servicios con altos estándares de excelencia."
  },
  {
    clase: 'confianza',
    nombre: "Confianza",
    descripcionCorta: "Actuamos bajo los criterios de cumplimiento, calidad, honestidad, respeto y responsabilidad generando un alto nivel de confianza para nuestras partes interesadas."
  },
]

export default dataValores