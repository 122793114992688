import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import normalizeString from "../../functions/normalizeString";
import SVG_HONDAS from '../../svg/SVG_HONDAS';

const colores = {
  claro: '#0E1627',
  principal: '#F2B84A'
}

const FichaProyecto2 = (props) => {
  const { proyecto } = props;

  const [height, setHeight] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);

  const titulo = useRef(null);
  const boton = useRef(null);
  const ficha = useRef(null);
  const fichaContenido = useRef(null);

  useEffect(() => {
    if (!!titulo.current && !!boton.current && !!ficha.current && !!fichaContenido.current) {
      const heightContenedor = () => {
        const heightFicha = ficha.current.getBoundingClientRect().height;
        const heightTitulo = titulo.current.getBoundingClientRect().height;
        const heightBoton = boton.current.getBoundingClientRect().height;
        const paddingFicha = parseInt(window.getComputedStyle(fichaContenido.current).getPropertyValue('padding-top'));

        const heightDescripcion = heightFicha - (heightBoton + heightTitulo + (paddingFicha * 3));
        setHeight(heightDescripcion);
      }

      heightContenedor();
      window.addEventListener('resize', heightContenedor);
      return () => {
        window.removeEventListener('resize', heightContenedor);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titulo, boton, ficha, fichaContenido]);


  const flipCard = () => setIsFlipped(!isFlipped);

  return (
    <div
      key={proyecto.id}
      className={
        `card-proyecto2 px-5 basis-full md:basis-2/4 lg:basis-1/4 mb-10 ${isFlipped ? 'card-proyecto2-flip' : ''}`
      }
      onClick={flipCard}
    >
      <div className="card-proyecto-inner">
        <div className="card-proyecto-front proyecto2 foto-redondeada flex flex-col justify-end"
          style={{
            backgroundImage: `url(${require(`../../img/proyectos${proyecto.imagen}`)})`
          }}
        >
          <SVG_HONDAS fill2={colores.principal} fill1={colores.claro} />
          <div className='informacion flex flex-col' style={{ backgroundColor: colores.principal }}>
            <div className='px-6 mb-2'>
              <p className='nombre text-18'>{proyecto.nombre}</p>
              <p className='empresa text-16'>{proyecto.cliente}</p>
            </div>
            <div className="flex mb-3 px-6 align-center ">
              <span className={`text-20 icon-${normalizeString(proyecto.sector)}`} />
              <p className="text-16 text-center italic mb-2 pl-2">{proyecto.sector}</p>
            </div>

          </div>
        </div>
        <div className="card-proyecto-back" ref={ficha}>
          <div className="proyecto2 info-proyecto" ref={fichaContenido}>
            <div className="flex flex-col content-between items-start" style={{ height: '100%' }}>
              <div ref={titulo}>
                <p className='nombre'>{proyecto.nombreCorto}</p>
              </div>
              <div className="descripcion-proyecto mt-1 mb-3" style={{ height: height }}>
                {proyecto.descripcion}
              </div>
              <Link ref={boton} className="w-[60%] boton-saber-mas text-center text-13 self-center justify-self-center" to='/contactenos'>Saber más</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default FichaProyecto2;
