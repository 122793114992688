import { Children } from 'react/cjs/react.production.min';

const SeccionDividida = (props) => {
  const { children, paddingLeft, paddingRight } = props;
  const _children = Children.toArray(children);

  return (
    <div className="container mx-auto flex flex-col flex-wrap items-center lg:flex-nowrap md:flex-row py-10 lg:py-20 info justify-between px-10 md:px-0">
      <div className={`basis-full lg:basis-5/12 col-der pb-8 lg:pb-0 pr-0 lg:${paddingRight}`}>
        {_children[0]}
      </div>
      <div className={`lg:basis-6/12 col-izq pl-0 lg:${paddingLeft}`}>
        {_children[1]}
      </div>
    </div>
  )
}

export default SeccionDividida;