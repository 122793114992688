import { useState, useRef, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Divide as Hamburger } from 'hamburger-react'
import normalizeString from './../../functions/normalizeString';
import useIsMobile from './../../utilities/hooks/useIsMobile';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const opcionesHeader = [{ nombre: "Inicio", link: '/' }, { nombre: "Nosotros" }, { nombre: "Servicios" }, { nombre: "Proyectos" }, { nombre: "Contáctenos" },];

  const { isMobile } = useIsMobile();

  const [isOpen, setOpen] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const menuMovil = useRef(null);

  const cerrarAbrirMenu = (value) => {
    const valueMenu = value ?? !isOpen;
    setOpen(valueMenu);
  };

  const location = useLocation(cerrarAbrirMenu, menuMovil);

  useEffect(() => {
    if (location.pathname === '/') {
      window.scrollTo(0, 0);
    }
  }, [location.pathname])

  useEffect(() => {
    const scroll = () => {
      const scroll = window.pageYOffset !== 0;
      setIsScroll(scroll);
    }
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    }
  }, [])

  return (
    <>
      <header className={`flex flex-row justify-between bg-principal-azul px-5 lg:px-20 py-3 ${isScroll ? 'fixed' : 'relative'}`}>
        <div className="lg:basis-1/3">
          <Link to='/'>
            <img
              alt="Logo Inascol"
              className="logo-inascol"
              src={require('../../img/logos/inascol.png')}
            />
          </Link>
        </div>
        <div className="header-menu flex flex-row lg:basis-1/3 justify-end items-center">
          {
            isMobile && <Hamburger toggled={isOpen} toggle={setOpen} />
          }

          {
            !isMobile && opcionesHeader.map(opcionHeader => {
              const link = !!opcionHeader.link ? opcionHeader.link : `/${normalizeString(opcionHeader.nombre)}`;
              return (
                <div className="text-blanco pl-8" key={opcionHeader.nombre}>
                  <NavLink className='opcion' activeClassName='opcion-activa' to={`${link}`}>
                    {opcionHeader.nombre}
                  </NavLink>
                </div>
              )
            })
          }
          {
            isMobile && <div ref={menuMovil} className={`menu-movil px-0 w-[65%] ${isOpen ? "menu-open" : "menu-close"}`}>
              {
                opcionesHeader.map(opcionHeader => {
                  const link = !!opcionHeader.link ? opcionHeader.link : `/${normalizeString(opcionHeader.nombre)}`;
                  const clase = link === normalizeString(location.pathname) ? "opcion-activa-movil" : "";
                  return (
                    <div>
                      <Link
                        to={link}
                        className={clase}
                        key={`${opcionHeader.nombre}`}
                      >
                        <div className={`d-flex align-items-center justify-content-center py-3 items-menu`} onClick={() => cerrarAbrirMenu(false)}>
                          {opcionHeader.nombre}
                        </div>
                      </Link>
                    </div>
                  )
                })
              }
            </div>
          }
          {
            isMobile && isOpen && <div className="cerrar-menu" onClick={() => { cerrarAbrirMenu(false) }} />
          }
        </div>
      </header>
    </>
  )
}

export default Header