import { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
// import ScrollToTop from './utilities/ScrollToTop';
import Footer from './components/Footer';
import Home from './components/home';
import Header from './components/header/index';
import Nosotros from './components/nosotros/index';
import Servicios from './components/servicios/index';
import Contacto from './components/contacto';
import ProyectosPrincipal from './components/proyectos/index';

function App() {

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/nosotros" component={Nosotros} />
        <Route exact path="/servicios" component={Servicios} />
        <Route exact path="/servicios/:seccion" component={Servicios} />
        <Route exact path="/contactenos" component={Contacto} />
        <Route exact path="/proyectos" component={ProyectosPrincipal} />
        <Route exact path="/proyectos/:seccion" component={ProyectosPrincipal} />
        <Route exact path="/proyectos/:seccion/:subsection" component={ProyectosPrincipal} />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
