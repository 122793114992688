import { Link } from 'react-router-dom';
import SVG_PUNTAS from '../svg/SVG_PUNTAS';

const Footer = () => {

  // const redes = [
  //   { nombre: 'linkedin', link: '/' },
  //   { nombre: 'twitter', link: '/' },
  //   { nombre: 'facebook', link: '/' },
  //   { nombre: 'youtube', link: '/' }
  // ]

  return (
    <>
      <footer>
        <div className='fondo-puntas'>
          <SVG_PUNTAS fill={'#093959'} />
        </div>
        <div className="footer px-5 lg:px-20">
          <div className='flex flex-col md:flex-row justify-between lg:items-center py-4'>
            <div className="basis-1/3">
              <Link to='/'>
                <img
                  alt="Logo Inascol"
                  className="logo-inascol"
                  src={require('../img/logos/inascol.png')}
                />
              </Link>
              <div className='info-contacto'>
                <div className="flex items-center mt-2">
                  <span className="icon-pais mt-0.5 mr-3" />
                  <p className="break-words" href="mailto:contacto@makemake.com.co">Bogotá, Colombia</p>
                </div>
                <div className="flex items-center mt-2">
                  <span className="icon-correo mt-0.5 mr-3" />
                  <a className="break-words" href="mailto:inascol@inascol.com">inascol@inascol.com</a>
                </div>
                <div className="flex items-center mt-2">
                  <span className="icon-telefono mt-0.5 mr-3" />
                  <a className="break-words" href="tel:6013405407">(57) + 60 + 1 + 3405407</a>
                </div>
                <div className="flex items-center mt-2">
                  <span className="icon-celular mt-0.5 mr-3" />
                  <a className="break-words" href="tel:3108163032">310 816 3032</a>
                </div>
                <div className="flex items-center mt-2">
                  <span className="icon-ubicacion mt-0.5 mr-3" />
                  <p className="break-words">Cra 13 No. 35 - 43 Of. 701</p>
                </div>
              </div>

              {/* Redes */}
              {/* <div className='redes mt-8 flex'>
                {redes.map(red =>
                  <div className='red mr-5 flex items-center justify-center' key={red.nombre}>
                    <a href={red.link}>
                      <span className={`icon-${red.nombre}`} />
                    </a>
                  </div>
                )}
              </div> */}


              {/* Partner */}
              <div className='partner mt-8'>
                <h2>Partner</h2>
                <img
                  alt="Logo Inascol"
                  className="logo-esri"
                  src={require('../img/partner/esri_bronze.png')}
                />
              </div>
            </div>

            {/* Mapa */}
            <div className='mapa flex flex items-center justify-center md:mx-auto lg:ml-0 lg:mr-0'>
              <div className='mapa-borde flex items-center justify-center' />
              <iframe src="https://www.arcgis.com/home/webmap/embedViewer.html?webmap=03e65cca54c640f89a10d1dcd5af17f4&amp;extent=-74.0701,4.6218,-74.0648,4.6255" width="100" height="100" allowFullScreen="" loading="lazy" title='mapa-inascol'></iframe>
            </div>
          </div>
          <div className='derechos-reservados text-principal-amarillo text-center text-bold py-4 lg:py-5'>
            <p>© Todos los  derechos reservados. Inascol SAS 2022.</p>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer;