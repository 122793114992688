import React from "react";
const ingeominas = {
    proyecto1: {
    nombre: 'Sistema atención al Cliente',
    nombreCorto: 'SAAC',
    cliente: 'Ingeominas',
    imagen: '/ingeominas/saac/ficha.png',
    sector: 'Mineria',
    descripcion: (() => (
      <>
        <p><span className="texto-resaltado">Aplicativo web</span> que apoya los procesos de servicio al cliente. En Internet cuenta con funcionalidades de: <span className="texto-resaltado">adquisición de productos en línea</span>, reservas del material de la biblioteca, reservar visitas al Museo Geológico José Royo y Gómez, <span className="texto-resaltado">quejas y reclamos,</span> derechos de petición, encuestas y <span className="texto-resaltado">búsqueda de productos</span>. En la Intranet, posee funcionalidades de administración, <span className="texto-resaltado">reportes</span> y gestión de cada uno de los temas manejados en el área de servicio al cliente. </p>
      </>
    ))(),
  },
    proyecto2: {
    nombre: 'SIGER A ESCALA 1:100.000',
    nombreCorto: 'SIGER 100K',
    cliente: 'Ingeominas',
    imagen: '/ingeominas/siger/ficha.png',
    sector: 'Gobierno',
    descripcion: (() => (
      <>
        <p>Sistema que administra la información geográfica y alfanumérica de <span className="texto-resaltado">geología, geofísica y geoquímica</span> a escala 1:100.000 y <span className="texto-resaltado">sensores remotos</span> .</p>
        <p><span className="texto-resaltado">Geología:</span> Contacto geológico, unidad litoestratigráfica, columna estratigráfica, dato estructural, pliegue, falla geológica, volcán, estructura secundaria y corte geológico.</p>
        <p><span className="texto-resaltado">Geofísica:</span> Area de estudio, líneas geofísicas, punto gravimétrico, punto magnetométrico, banda magnética, sondeo electro-vertical, sondeo electromagnético, punto de gammaespectrometría, pozo, tomografía 2D, polarización inducida, sísmica de refracción y reflexión, áreas con modelos geofísicos.</p>
        <p><span className="texto-resaltado">Geoquímica:</span> Libreta de campo, punto de observación, información general de la muestra, y análisis de la muestra.</p>
      </>
    ))(),
  }
}

export default ingeominas;