const SVG_HONDAS = (props) => {
  const { fill1, fill2 } = props;
  
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1943.24 237.37">
      <defs>
      </defs>
      <path style={{fill: fill1}} d="M876.79,139.23a141.58,141.58,0,0,0,39.33,1.51c21.37-2.16,41.49-9.06,61.07-18.4,20.95-10,40.67-22.53,60.48-34.91s39.79-24.8,60.82-34.69c-15.2-7.78-30.39-15.58-46-22.31-21.31-9.17-43.13-16.27-66-18-26-1.93-51.1,2.73-75.53,12.71C885.3,35.62,861.43,50,837.48,64.32c-20,11.92-40,23.78-61.14,33,12.26,6.53,24.48,13.16,36.9,19.32C833.67,126.77,854.6,135.28,876.79,139.23Z" />
      <path style={{fill: fill1}} d="M47.59,92.26c-8.8-4.49-17.71-8.71-26.79-12.49A204.77,204.77,0,0,0,0,72.35V119c14.53-7.82,28.71-16.41,43.18-24.33Z" />
      <path style={{fill: fill1}} d="M1426,104.2c20.15-1.66,39.53-7.52,58.42-15.4,14.8-6.19,29.11-13.62,43.37-21.18,13.25-7,26.29-14.5,39.52-21.55-7.22-3.48-14.51-6.79-21.9-9.84-20.29-8.35-41.14-14-62.74-15.44a179,179,0,0,0-59.8,6.5c-25.16,6.92-49,18-72.77,29-14.44,6.7-28.84,13.52-43.53,19.48,20.23,9.28,40.65,17.9,62,23.5A170.07,170.07,0,0,0,1426,104.2Z" />
      <path style={{fill: fill1}} d="M369.71,162.08a133.26,133.26,0,0,0,45,2.69c20.39-2.18,39.48-9.2,58.07-18.37,18-8.89,35.12-19.73,52.18-30.64,15.62-10,31.16-20.13,47.27-29.12,7.13-4,14.35-7.72,21.7-11.08L580.3,68.32C560.88,58.08,541.4,48,520.86,40.71c-31-11-62.18-13.25-93.79-3.36-19.9,6.22-38.42,15.95-56.42,27-19.93,12.21-39,26-58.52,39-9.38,6.25-18.84,12.31-28.5,17.9,11.11,7,22.3,13.8,33.79,20C334.17,150.25,351.42,157.78,369.71,162.08Z" />
      <path style={{fill: fill1}} d="M1852,64.12a264.72,264.72,0,0,0,54.93,14.22,181.89,181.89,0,0,0,36.35,1.16V5.55A150.88,150.88,0,0,0,1910.38.14c-18.72-.89-37,2.45-54.89,8.79-21.49,7.64-41.41,19-60.93,31.17l5.25,2.29C1817.09,50,1834.32,57.63,1852,64.12Z" />
      <path style={{fill: fill2}} d="M1906.89,78.34A264.72,264.72,0,0,1,1852,64.12c-17.64-6.49-34.87-14.17-52.15-21.73l-5.25-2.29c-16.83-7.33-33.73-14.45-51.17-19.83-23.75-7.32-47.81-11.6-72.63-9.43A197.16,197.16,0,0,0,1638,16.41c-20.33,5.29-39.62,13.85-58.59,23.37-4.07,2-8.1,4.14-12.12,6.29-13.23,7-26.27,14.53-39.52,21.55-14.26,7.56-28.57,15-43.37,21.18-18.89,7.88-38.27,13.74-58.42,15.4a170.07,170.07,0,0,1-57.38-4.92c-21.4-5.6-41.82-14.22-62-23.5q-5.58-2.57-11.16-5.17c-20.69-9.68-41.31-19.55-62.88-26.67-14.38-4.74-29-8.33-43.94-9.52a154,154,0,0,0-34.46.84c-18,2.62-35.21,8.2-52,15.79l-3.64,1.69c-21,9.89-40.94,22.27-60.82,34.69s-39.53,24.93-60.48,34.91c-19.58,9.34-39.7,16.24-61.07,18.4a141.58,141.58,0,0,1-39.33-1.51c-22.19-3.95-43.12-12.46-63.55-22.59-12.42-6.16-24.64-12.79-36.9-19.32Q770,93.93,763.6,90.6c-16.32-8.48-32.81-16.52-50.14-22.23-20.61-6.78-41.48-10.69-63.1-8.49a167.46,167.46,0,0,0-25.8,4.52A190.57,190.57,0,0,0,594,75.56c-7.35,3.36-14.57,7.11-21.7,11.08-16.11,9-31.65,19.13-47.27,29.12-17.06,10.91-34.15,21.75-52.18,30.64-18.59,9.17-37.68,16.19-58.07,18.37a133.26,133.26,0,0,1-45-2.69c-18.29-4.3-35.54-11.83-52.29-20.87-11.49-6.2-22.68-13-33.79-20-12.18-7.64-24.27-15.47-36.57-22.87-18.92-11.39-38.16-22-58.91-28.7-17.93-5.78-36.15-8.67-55-7.06a166.77,166.77,0,0,0-43.4,9.94c-14.54,5.33-28.46,12.31-42.2,19.74l-4.41,2.39C28.71,102.57,14.53,111.16,0,119V237.37H1943.24V79.5A181.89,181.89,0,0,1,1906.89,78.34Z" />
    </svg>
  )
}

export default SVG_HONDAS;