import TituloSeccion from "../generales/TituloSeccion";
import dataServicios from './../../data/dataServicios';

const ServiciosPrincipal = () => {
  return (
    <>
      <TituloSeccion titulo='Servicios' />

      <div className="pb-10 bg-gris-claro tabla-servicios">
        <div className="container mx-auto">
          <div className="w-11/12 mx-auto">
            <div className="flex flex-wrap justify-end justify-center pt-20 seccion-servicios">
              {
                dataServicios.map(servicio =>
                  <div className="md:basis-1/2 lg:basis-4/12 flex servicio pb-10 lg:pb-0 lg:mb-16">
                    <div className='basis-10/12 text-center mx-auto'>
                      <span className={`text-100 icon-${servicio.clase}`}></span>
                      <div className='mt-5'>
                        <p className='mb-3 text-20 opensans-700'>{servicio.nombre}</p>
                        <p className='opensans-300 text-17 mb-8'>{servicio.descripcionCorta}</p>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default ServiciosPrincipal;