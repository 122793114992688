import React from "react";
const cundinamarca = {
  proyecto1: {
    nombre: 'Mapas de Cundinamarca',
    nombreCorto: 'Mapas de Cundinamarca',
    cliente: 'Gobernación de Cundinamarca',
    imagen: '/cundinamarca/ficha.png',
    sector: 'Gobierno',
    descripcion: (() => (
      <>
        <p><span className="texto-resaltado">Aplicativo web</span> que permite a los usuarios del sitio, acceder y descargar <span className="texto-resaltado">mapas finales</span> de diferentes temáticas, navegar de forma interactiva por las capas geográficas, vector y raster, mediante la utilización de <span className="texto-resaltado">visores de diferente complejidad</span>, consultar <span className="texto-resaltado">sitios turísticos</span> e <span className="texto-resaltado">información estadística</span>, entre otros. Es un sistema abierto y ampliable que posibilita, en forma sencilla, incorporar nuevos contenidos al portal sin necesidad de desarrollos de software</p>
      </>
    ))(),
  },
}

export default cundinamarca;