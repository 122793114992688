const dataServicios = [
  {
    clase: 'consultoria',
    descripcion: (() => (
      <>
        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam.</p>
      </>
    ))(),
    descripcionCorta: "Revisión de la plataforma tecnológica actual versus nuevos requerimientos.  Diseño de la infraestructura de HW y SW requerida. Estructuración de la base de datos geográfica acorde con estándares actualizados",
    imagen: 'consultoria.jpg',
    nombre: "CONSULTORIA EN SISTEMAS DE INFORMACIÓN GEOGRÁFICA",
    subservicios: [
      {
        clase: 'arquitectura_software',
        contenido: (() => (
          <>
            <p>Realizamos un estudio detallado de la estructura de los diferentes sistemas empresariales de nuestros clientes, lo que nos permite definir la arquitectura más conveniente para resolver las necesidades específicas de cada empresa.</p>
            <p>Buscamos que una arquitectura empresarial combine estratégicamente los aspectos propios del negocio, las aplicaciones, los sistemas de información y las plataformas tecnológicas, teniendo en cuenta conceptos de integración, interoperabilidad y mantenibilidad de sistemas.</p>
          </>
        ))(),
        descripcion: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.',
        img: 'arquitectura-empresarial.jpg',
        nombre: 'Arquitectura empresarial de software.',
      },
      {
        clase: 'gerencia_proyectos',
        contenido: (() => (
          <>
            <p>Contamos con amplia experiencia y conocimiento en gerencia de proyectosy hemos logrado la ejecución exitosa de proyectos en diferentes sectores utilizando la metodología definida por el Project Management Institute – PMI. Permítanos acompañarle en cualquiera de las etapas de iniciación, planeación, ejecución, seguimiento y control, y cierre.</p>
            <p>Con nuestra gerencia de proyectos le permitiremos: la consecución de los objetivos y el alcance propuesto, la optimización de la relación costo-beneficio, la identificación y seguimiento a riesgos, el aprovechamiento máximo de los recursos y el balance correcto de actividades, tiempo, costo y recurso.</p>
          </>
        ))(),
        descripcion: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.',
        img: 'gerencia-proyectos.png',
        nombre: 'Gerencia de proyectos.',
      },
      {
        clase: 'planeacion',
        contenido: (() => (
          <>
            <p>Es una metodología que permite mantener alineadas las estrategias de sistemas de información con las estrategias de negocio. Nuestro servicio se enfoca en identificar:</p>
            <ul className="lista-desordenada">
              <li>Necesidades de información que requieran un tratamiento tecnológico.</li>
              <li>Necesidades de información que se requieren interna y/o externamente.</li>
              <li>Actualizaciones o creación de sistemas de información que realicen la integración requerida.</li>
            </ul>
            <p>Por último, se define la arquitectura empresarial de software, con recomendaciones y estrategias que se deben realizar a corto y mediano plazo.</p>
          </>
        ))(),
        descripcion: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.',
        img: 'planeacion-estrategica.jpg',
        nombre: 'Planeación estratégica de sistemas de información.',
      },
      {
        clase: 'ambiental',
        contenido: (() => (
          <>
            <p>Los estudios ambientales son mecanismos de prevención o corrección, que se utilizan en la gestión ambiental, para proteger la salud pública y el ambiente en el que vivimos.</p>
            <p>Contamos con profesionales con amplia experiencia en:</p>
            <ul className="lista-desordenada">
              <li>Planes de Contingencia.</li>
              <li>Estudios de Impacto ambiental.</li>
              <li>
                Planes de manejo ambiental
                <ul className="sublista-desordenada">
                  <li>Planes de ordenamiento y manejo de cuencas.</li>
                  <li>Interventorías ambientales.</li>
                  <li>Manejo de recurso naturales (hídricos, suelo, aire).</li>
                  <li>Manejo de residuos (sólidos, líquidos especiales)</li>
                </ul>
              </li>
            </ul>
            <p>Con nuestra gerencia de proyectos le permitiremos: la consecución de los objetivos y el alcance propuesto, la optimización de la relación costo-beneficio, la identificación y seguimiento a riesgos, el aprovechamiento máximo de los recursos y el balance correcto de actividades, tiempo, costo y recurso.</p>
          </>
        ))(),
        descripcion: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.',
        img: 'estudios-ambientales.jpg',
        nombre: 'Estudios ambientales.',
      }
    ]
  },
  {
    clase: 'visoresGeograficos',
    descripcion: (() => (
      <>
        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam.</p>
      </>
    ))(),
    descripcionCorta: "Estructuración de servicios geográficos, publicación de visores temáticos y tableros de control que relacionan diferentes tipos de datos, permitiendo visualizar datos consolidados, estadísticas e indicadores.",
    nombre: "VISORES GEOGRÁFICOS Y DASHBOARD",
    imagen: 'visores_geograficos.png',
    subservicios: [
      {
        clase: 'arquitectura_software',
        contenido: (() => (
          <>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam.</p>
          </>
        ))(),
        descripcion: 'Lorem ipsum dolor sit amet, consectetuer adipiscisng elit, sed diam nonummy nibh euismod tincidunt.',
        img: 'arquitectura-empresarial.jpg',
        nombre: 'Lorem ipsum dolor sit amet, consectetuer a',
      },
      {
        clase: 'arquitectura_software',
        contenido: (() => (
          <>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam.</p>
          </>
        ))(),
        descripcion: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.',
        img: 'arquitectura-empresarial.jpg',
        nombre: 'Lorem ipsum dolor sit amet, consectetuer a',
      },
      {
        clase: 'arquitectura_software',
        contenido: (() => (
          <>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam.</p>
          </>
        ))(),
        descripcion: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.',
        img: 'arquitectura-empresarial.jpg',
        nombre: 'Lorem ipsum dolor sit amet, consectetuer a',
      },
    ]
  },
  {
    clase: 'administracion',
    descripcion: (() => (
      <>
        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam.</p>
      </>
    ))(),
    descripcionCorta: "Configuración de la base de datos geográfica dividida por temáticas y responsabilidades, definición de roles de usuario, asignación de permisos, publicación de servicios, administración de la GDB.",
    imagen: 'arcgis_logo.png',
    nombre: "ADMINISTRACIÓN DE ARCGIS ENTERPRISE",
    subservicios: [
      {
        clase: 'arquitectura_software',
        contenido: (() => (
          <>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam.</p>
          </>
        ))(),
        descripcion: 'Lorem ipsum dolor sit amet, consectetuer adipiscisng elit, sed diam nonummy nibh euismod tincidunt.',
        img: 'arquitectura-empresarial.jpg',
        nombre: 'Lorem ipsum dolor sit amet, consectetuer a',
      },
      {
        clase: 'arquitectura_software',
        contenido: (() => (
          <>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam.</p>
          </>
        ))(),
        descripcion: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.',
        img: 'arquitectura-empresarial.jpg',
        nombre: 'Lorem ipsum dolor sit amet, consectetuer a',
      },
      {
        clase: 'arquitectura_software',
        contenido: (() => (
          <>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam.</p>
          </>
        ))(),
        descripcion: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.',
        img: 'arquitectura-empresarial.jpg',
        nombre: 'Lorem ipsum dolor sit amet, consectetuer a',
      },
    ]
  },
  {
    clase: 'desarrolloSitemas',
    descripcion: (() => (
      <>
        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam.</p>
      </>
    ))(),
    descripcionCorta: "Análisis, diseño, implementación, migración y puesta en operación de sistemas de información conforme a las necesidades particulares del cliente, utilizando las bases de datos alfanuméricas y geográficas.",
    imagen: 'desarrollo-de-sistemas.jpg',
    nombre: "DESARROLLO DE SISTEMAS DE INFORMACIÓN A LA MEDIDA",
    subservicios: [
      {
        clase: 'arquitectura_software',
        contenido: (() => (
          <>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam.</p>
          </>
        ))(),
        descripcion: 'Lorem ipsum dolor sit amet, consectetuer adipiscisng elit, sed diam nonummy nibh euismod tincidunt.',
        img: 'arquitectura-empresarial.jpg',
        nombre: 'Lorem ipsum dolor sit amet, consectetuer a',
      },
      {
        clase: 'arquitectura_software',
        contenido: (() => (
          <>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam.</p>
          </>
        ))(),
        descripcion: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.',
        img: 'arquitectura-empresarial.jpg',
        nombre: 'Lorem ipsum dolor sit amet, consectetuer a',
      },
      {
        clase: 'arquitectura_software',
        contenido: (() => (
          <>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam.</p>
          </>
        ))(),
        descripcion: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.',
        img: 'arquitectura-empresarial.jpg',
        nombre: 'Lorem ipsum dolor sit amet, consectetuer a',
      },
    ]
  },
  {
    clase: 'documentos',
    descripcion: (() => (
      <>
        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam.</p>
      </>
    ))(),
    descripcionCorta: "Sistema de gestión minera que facilita la administración de la información relacionada con las obligaciones y eventos tiene el título en cada una de sus etapas. Estructuración de las capas geográficas siguiendo los estándares definidos por ANM, ANLA y BIM.",
    imagen: 'gestion_informacion.jpg',
    nombre: "GESTIÓN DE INFORMACIÓN DE TÍTULOS MINEROS",
    subservicios: [
      {
        clase: 'arquitectura_software',
        contenido: (() => (
          <>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam.</p>
          </>
        ))(),
        descripcion: 'Lorem ipsum dolor sit amet, consectetuer adipiscisng elit, sed diam nonummy nibh euismod tincidunt.',
        img: 'arquitectura-empresarial.jpg',
        nombre: 'Lorem ipsum dolor sit amet, consectetuer a',
      },
      {
        clase: 'arquitectura_software',
        contenido: (() => (
          <>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam.</p>
          </>
        ))(),
        descripcion: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.',
        img: 'arquitectura-empresarial.jpg',
        nombre: 'Lorem ipsum dolor sit amet, consectetuer a',
      },
      {
        clase: 'arquitectura_software',
        contenido: (() => (
          <>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam.</p>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, <span className="texto-resaltado">sed diam nonummy</span> nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. <span className="texto-resaltado">Ut wisi enim ad minim</span> veniam.</p>
          </>
        ))(),
        descripcion: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.',
        img: 'arquitectura-empresarial.jpg',
        nombre: 'Lorem ipsum dolor sit amet, consectetuer a',
      },
    ]
  },
]

export default dataServicios;