
import dataTestimonios from './dataTestimonios';
// import Swiper core and required components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard, EffectCoverflow } from 'swiper';
import '../../../../node_modules/swiper/modules/effect-coverflow/effect-coverflow.min.css';
import SVG_HONDAS_VERTICAL from '../../../svg/SVG_HONDAS_VERTICAL';
import useIsMobile from './../../../utilities/hooks/useIsMobile';

const Testimonios = () => {
  const { isMobile } = useIsMobile();

  return (
    <div className="testimonios py-10">
      <div className="container m-auto">
        <h3 className="titulo-subsecciones">Testimonios</h3>

        <div className='flex p-5 lg:py-10'>
          <Swiper
            autoplay={{
              delay: 10000,
              disableOnInteraction: false
            }}
            centeredSlides={true}
            className="md:basis-11/12 mx-auto swiper-testimonios"
            coverflowEffect={{
              depth: 500,
              modifier: 1,
              rotate: -15,
              slideShadows: true,
            }}
            effect="coverflow"
            grabCursor={true}
            loop={true}
            modules={[Autoplay, Keyboard, EffectCoverflow]}
            slidesPerView="auto"
          >
            {
              dataTestimonios.map((testimonio) =>
                <SwiperSlide className="testimonio" key={`testimonio${testimonio.id}`}>
                  <div className="flex" style={{ height: "100%" }}>

                    {
                      !isMobile && (
                        <div className="basis-3/12 informacion flex">
                          <div className='basis-9/12 datos' style={{ backgroundColor: testimonio.color.principal }}>
                          </div>
                          <div className='ondas-verticales basis-2/12'>
                            <SVG_HONDAS_VERTICAL fill2={testimonio.color.principal} fill1={testimonio.color.claro} />
                          </div>
                        </div>
                      )
                    }

                    <div className="lg:basis-10/12 texto flex">
                      <div className='px-4'>
                        <p className='icon-cita comilla' style={{ color: testimonio.color.claro }} />
                        {testimonio.testimonio.map((parrafo, index) => <p key={`${testimonio.id}${index}`}>{parrafo}</p>)}
                        <p className='nombre'>{testimonio.nombre}</p>
                        <div className="flex redes">
                          {testimonio.redes.map(red => <a className='mx-2' href={red.link} target='_blank' rel="noreferrer"><span className={`icon-${red.nombre}`}></span></a>)}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              )
            }
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Testimonios