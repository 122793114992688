import React from "react";
const minercol = {
  rmn: {
    nombre: 'Registro Minero Nacional',
    nombreCorto: 'RMN - Módulo Geográfico',
    cliente: 'Minercol',
    imagen: '/minercol/rmn/ficha.png',
    sector: 'Mineria',
    descripcion: (() => (
      <>
        <p>Genera polígonos a partir de las <span className="texto-resaltado"> coordenadas XY o rumbos y distancias,</span> valida <span className="texto-resaltado">sistema de coordenadas, superposiciones de área,</span> realiza <span className="texto-resaltado">recorte automático</span>, almacena los cambios <span className="texto-resaltado">históricos</span> del título después de realizar <span className="texto-resaltado">devolución o integración de áreas</span> , reportes geográficos que <span className="texto-resaltado">integran datos </span> de la base de datos alfanumérica SIAL, <span className="texto-resaltado"> visor geográfico </span> con funcionalidades de edición. </p>
      </>
    ))(),
  },
}

export default minercol;