import { Link } from "react-router-dom";

const SaberMas = () => {

  return (
    <div className="saber-mas fondo-amarillo" >
      <div className="container mx-auto">
        <div className="w-10/12 mx-auto">
          <h3 className="titulo-subsecciones">¿Quiere saber más?</h3>
          <p className="lg:w-8/12 mx-auto text-20 text-blanco">Contáctenos y alguien de nuestro equipo se comunicará, para responder a su solicitud lo más pronto posible.</p>
          <button className="boton-borde boton-saber-mas">
            <Link to="/contactenos">
              Escríbanos
            </Link>
          </button>
        </div>
      </div>
    </div>
  )
}

export default SaberMas;