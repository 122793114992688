import React from "react";
const ecopetrol = {
  proyecto1: {
    nombre: 'SIG Inmobiliario',
    nombreCorto: 'SIG Inmobiliario',
    cliente: 'Ecopetrol',
    imagen: '/ecopetrol/siginmo/ficha.png',
    sector: 'Hidrocarburos',
    descripcion: (() => (
      <>
        <p><span className="texto-resaltado">Sistema de información geográfica</span>  que administra los <span className="texto-resaltado">predios urbanos, rurales,</span> de propiedad o no, de Ecopetrol, y las limitaciones de derecho al dominio (servidumbres y ocupaciones), registrando datos generales de tipo <span className="texto-resaltado">legal, tributaria, datos de catastro,</span> aspectos físicos, justificación al derecho de propiedad, <span className="texto-resaltado">linderos</span>, daños, <span className="texto-resaltado">impuestos,</span> construcciones, reclamaciones, visitas, entre otras. Generación de <span className="texto-resaltado">reportes gráficos</span> como la ficha técnica del inmueble. 
</p>
      </>
    ))(),
  },
  proyecto2: {
    nombre: 'SIGVEP',
    nombreCorto: 'SIGVEP',
    cliente: 'Ecopetrol',
    sector: 'Hidrocarburos',
    imagen: '/ecopetrol/sigvep/ficha.png',
    descripcion: (() => (
      <>
        <p>Sistema de información geográfica <span className="texto-resaltado">SIG</span> que permite administrar, capturar, actualizar y consulta de la información alfanumérica y geográfica de <span className="texto-resaltado">pozos</span>, <span className="texto-resaltado">Sísmica</span>, <span className="texto-resaltado">Contratos petroleros</span> de exploración y producción (<span className="texto-resaltado">Tierras</span>) y <span className="texto-resaltado">campos petroleros</span>, almacenada en la base de datos con el estándar <span className="texto-resaltado">PPDM</span>.</p>
        <p>Cuenta con herramientas para generar poligonales a partir de coordenadas en un archivo y herramientas de <span className="texto-resaltado">Conversión de datos</span> entre ArcGIS y Zycor, Geographix y Geoframe.</p>
      </>
    ))(),
  },
  proyecto3: {
    nombre: 'MapExplorer',
    nombreCorto: 'Mapexplorer',
    cliente: 'Ecopetrol',
    imagen: '/ecopetrol/mapexplorer/ficha.png',
    sector: 'Hidrocarburos',
    descripcion: (() => (
      <>
        <p><span className="texto-resaltado">Motor de búsqueda de metadatos </span> desarrollado para la <span className="texto-resaltado">web</span>, que permite realizar búsquedas combinando <span className="texto-resaltado">información alfanumérica</span> y ubicación por <span className="texto-resaltado">coordenadas</span> seleccionadas a partir de un <span className="texto-resaltado">mapa</span>. El resultado de la búsqueda de metadatos presenta el resumen, la muestra gráfica y su enlace para ir directamente a <span className="texto-resaltado">consultarlo en un visor geográfico</span>. Permite visualizar la información de metadato detallado siguiendo el mismo esquema de la norma técnica <span className="texto-resaltado">NTC 4611</span>. </p>
      </>
    ))(),
  },
}

export default ecopetrol;