const dataNosotros = [
  {
    clase: 'mision',
    nombre: 'Misión',
    informacion:['Proveer soluciones informáticas dinámicas, innovadoras, con calidad y un valor agregado, que satisfagan las necesidades particulares de nuestros clientes, convirtiéndonos en su principal aliado. ']
  },
  {
    clase: 'vision',
    nombre: 'Visión',
    informacion:['Ser un aliado estratégico para cada uno de nuestros clientes, que los apoye y oriente para generar productos integrales que respalden su operación, análisis y estrategias a desarrollar, caracterizándonos por la calidad, cumplimiento e innovación en el desarrollo de sistemas de información.']
  },
  {
    clase: 'politicas',
    nombre: 'Política de calidad',
    informacion:['Somos una empresa dedicada a la consultoría y desarrollo de sistemas de información geográfica, comprometida con la generación de la mayor satisfacción de nuestros clientes, cuidado del bienestar de las partes interesadas evitando enfermedades, accidentes laborales y daños a la propiedad mediante la identificación de peligros, valoración y control de riesgos, el mejoramiento continuo de los procesos, cumplimiento de requisitos legales, cuidado del medio ambiente y responsabilidad social por medio de la asignación de los recursos necesarios.']
  }
]

export default dataNosotros;