import minercol from "./minercol";
import argos from './argos';
import ecopetrol from './ecopetrol';
import antioquia from './antioquia';
import upme from './upme';
import ingeominas from './ingeominas';
import cundinamarca from './cundinamarca';



const dataProyectos = {
  minercol,
  argos,
  ecopetrol,
  antioquia,
  upme,
  ingeominas,
  cundinamarca
}

export default dataProyectos;