const SVG_HONDAS_VERTICAL = (props) => {
  const { fill1, fill2 } = props;

  return (
    <svg style={{ height: "100%" }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 237.37 1943.24">
      <path style={{ fill: fill1 }} d="M98.14,876.79a142,142,0,0,0-1.51,39.33c2.17,21.37,9.06,41.49,18.4,61.07,10,20.95,22.54,40.67,34.92,60.48s24.79,39.79,34.68,60.82c7.78-15.2,15.58-30.39,22.31-46,9.17-21.31,16.27-43.13,18-66,1.94-26-2.73-51.1-12.7-75.53-10.47-25.62-24.88-49.49-39.17-73.44-11.92-20-23.78-40-33-61.14-6.54,12.26-13.17,24.48-19.32,36.9C110.61,833.67,102.09,854.6,98.14,876.79Z" />
      <path style={{ fill: fill1 }} d="M145.12,47.59c4.48-8.8,8.7-17.71,12.48-26.79A204.84,204.84,0,0,0,165,0H118.4c7.81,14.53,16.4,28.71,24.33,43.18Z" />
      <path style={{ fill: fill1 }} d="M133.17,1426c1.67,20.15,7.52,39.53,15.41,58.42,6.18,14.8,13.61,29.11,21.17,43.37,7,13.25,14.5,26.29,21.56,39.52,3.48-7.22,6.79-14.51,9.83-21.9,8.35-20.29,14-41.14,15.44-62.74a179,179,0,0,0-6.5-59.8c-6.91-25.16-18-49-29-72.77-6.7-14.44-13.52-28.84-19.48-43.53-9.27,20.23-17.9,40.65-23.5,62A170.07,170.07,0,0,0,133.17,1426Z" />
      <path style={{ fill: fill1 }} d="M75.29,369.71a133.52,133.52,0,0,0-2.69,45c2.18,20.39,9.21,39.48,18.37,58.07,8.89,18,19.73,35.12,30.64,52.18,10,15.62,20.13,31.16,29.12,47.27,4,7.13,7.72,14.35,11.08,21.7,2.41-4.55,4.84-9.1,7.24-13.66,10.24-19.42,20.33-38.9,27.61-59.44,11-31,13.26-62.18,3.37-93.79-6.23-19.9-15.95-38.42-27-56.42-12.22-19.93-26-39-39-58.52-6.25-9.38-12.31-18.84-17.9-28.5-7,11.11-13.79,22.3-20,33.79C87.13,334.17,79.59,351.42,75.29,369.71Z" />
      <path style={{ fill: fill1 }} d="M173.25,1852A264.07,264.07,0,0,0,159,1906.89a181.15,181.15,0,0,0-1.16,36.35h74a150.26,150.26,0,0,0,5.4-32.86c.89-18.72-2.44-37-8.79-54.89-7.63-21.49-18.95-41.41-31.17-60.93q-1.14,2.63-2.29,5.25C187.42,1817.09,179.74,1834.32,173.25,1852Z" />
      <path style={{ fill: fill2 }} d="M159,1906.89A264.07,264.07,0,0,1,173.25,1852c6.49-17.64,14.17-34.87,21.73-52.15q1.16-2.62,2.29-5.25c7.34-16.83,14.46-33.73,19.83-51.17,7.32-23.75,11.6-47.81,9.43-72.63A198.18,198.18,0,0,0,221,1638c-5.3-20.33-13.85-39.62-23.37-58.59-2-4.07-4.15-8.1-6.29-12.12-7.06-13.23-14.54-26.27-21.56-39.52-7.56-14.26-15-28.57-21.17-43.37-7.89-18.89-13.74-38.27-15.41-58.42a170.07,170.07,0,0,1,4.92-57.38c5.6-21.4,14.23-41.82,23.5-62q2.57-5.58,5.18-11.16c9.68-20.69,19.54-41.31,26.66-62.88,4.74-14.38,8.34-29,9.52-43.94a154,154,0,0,0-.83-34.46c-2.62-18-8.21-35.21-15.8-52-.55-1.21-1.12-2.43-1.69-3.64-9.89-21-22.26-40.94-34.68-60.82S125,998.14,115,977.19c-9.34-19.58-16.23-39.7-18.4-61.07a142,142,0,0,1,1.51-39.33c4-22.19,12.47-43.12,22.6-63.55,6.15-12.42,12.78-24.64,19.32-36.9,2.25-4.24,4.5-8.48,6.71-12.74,8.48-16.32,16.52-32.81,22.23-50.14,6.79-20.61,10.69-41.48,8.49-63.1a166.27,166.27,0,0,0-4.52-25.8A189.62,189.62,0,0,0,161.81,594c-3.36-7.35-7.1-14.57-11.08-21.7-9-16.11-19.13-31.65-29.12-47.27C110.7,507.93,99.86,490.84,91,472.81c-9.16-18.59-16.19-37.68-18.37-58.07a133.52,133.52,0,0,1,2.69-45c4.3-18.29,11.84-35.54,20.87-52.29,6.21-11.49,13-22.68,20-33.79,7.65-12.18,15.47-24.27,22.88-36.57,11.39-18.92,22-38.16,28.7-58.91,5.77-17.93,8.66-36.15,7-55a166.75,166.75,0,0,0-9.93-43.4c-5.33-14.54-12.32-28.46-19.74-42.2l-2.39-4.41C134.8,28.71,126.21,14.53,118.4,0H0V1943.24H157.88A181.15,181.15,0,0,1,159,1906.89Z" />
    </svg>
  )
}


export default SVG_HONDAS_VERTICAL;