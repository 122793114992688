import SVG_PUNTAS from '../../svg/SVG_PUNTAS';

const TituloSeccion = (props) => {
  const { titulo } = props;

  return (
    <div className='container-titulo-seccion'>
      <div className='titulo-seccion flex'>
        <div className='basis-10/12 mx-auto '>
          <h2>{titulo}</h2>
        </div>
      </div>
      <div className='fondo-puntas'>
        <SVG_PUNTAS fill={'#093959'} />
      </div>
    </div>
  )
}

export default TituloSeccion;
