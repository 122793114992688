// import Header from "../header";
import Nosotros from './Nosotros';
import NuestroTrabajo from "./NuestroTrabajo";
import SaberMas from "./SaberMas";
import Testimonios from "./testimonios/index";
import Clientes from './clientes/index';
import Galeria from "./Galeria";
import CajaAzul from './../globales/CajaAzul';
import dataServicios from '../../data/dataServicios';

const Home = () => {
  return (
    <>
      {/* <Header /> */}
      <Galeria />
      <Nosotros />
      <CajaAzul
        data={dataServicios}
        titulo='¿Qué hacemos?'
        id='que-hacemos'
      />
      <NuestroTrabajo titulo='Nuestro trabajo' />
      <SaberMas />
      <Testimonios />
      <Clientes />
    </>
  )
}

export default Home;