// import Swiper core and required components
import { Autoplay, Keyboard, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// swiper bundle styles
import 'swiper/css/bundle'

// swiper core styles
import 'swiper/css'

// modules styles
import 'swiper/css/navigation'
import 'swiper/css/pagination'

// install Swiper components
// SwiperCore.use([Autoplay, Keyboard]);
const Galeria = () => {
  const rangoSlide = { min: 1, max: 6 };
  return (
    <>
      <div className="pb-14 galeria-home">
        <Swiper
          autoplay={{
            delay: 5000
          }}
          keyboard
          loop={true}
          modules={[Autoplay, Keyboard, Pagination]}
          slidesPerView={1}
          pagination={{
            clickable: true,
            dynamicBullets: true, 
            dynamicMainBullets: 2
          }}
        >
          {
            (() => {
              let slide=[]
              for (let i = rangoSlide.min; i < rangoSlide.max; i++){
                slide.push(
                  <SwiperSlide className='w-100' key={`slide${i}`}>
                    <img src={`${require(`../../img/home/galeria/banner${i}.png`)}`} alt={`slide${i}`}/>
                  </SwiperSlide>
                )
              }
              return slide;
            })()
          }
        </Swiper>
      </div>
    </>
  )
}

export default Galeria;