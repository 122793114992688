const SVG_PUNTAS = (props) => {
  const { fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.37 12.8">
        <pattern id="bg" patternUnits="userSpaceOnUse" width="85.37" height="12.8">
          <path style={{ fill: fill }} d="M83.73,10.4C78,7.07,72.06,4,66.25.68A3.48,3.48,0,0,0,62.19.63C56.64,3.85,50.94,6.75,45.45,10a6,6,0,0,1-2.77.93A6,6,0,0,1,39.91,10C34.42,6.75,28.72,3.85,23.18.63a3.5,3.5,0,0,0-4.07.05C13.31,4,7.39,7.07,1.64,10.4A4.39,4.39,0,0,1,0,11V12.8H85.37V11A4.39,4.39,0,0,1,83.73,10.4Z" transform="translate(0 0)"/>
        </pattern>
      <rect width="100vw" height="100%" fill="url(#bg)" />
    </svg>
  )
}


export default SVG_PUNTAS;
