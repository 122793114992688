const dataSectoresPrincipales = [
  {
    clase: 'mineria',
    nombre: "Minería",
    descripcion: [
      "Sistemas de gestión para la administración de títulos mineros, que facilitan y respaldan los trámites ante las autoridades competentes. Creación de visores de consulta de información geográfica de títulos, planeamiento minero, ambiental, predios y proyectos RSC. Estructuración de la GDB siguiendo los estándares de la ANM, ANLA y BIM."
    ]
  },
  {
    clase: 'hidrocarburos',
    nombre: "Hidrocarburos",
    descripcion: [
      "Sistemas de Información geográfica que administran y consultan información de contratos petroleros, pozos, sísmica, campos, geología, predios, proyectos de responsabilidad social, estudios de impacto ambiental, proyectos exploratorios, entre otros. Visores geográficos y tableros de control que relacionan la información geográfica con la alfanumérica."
    ]
  },
  {
    clase: 'ambiente',
    nombre: "Ambiente",
    descripcion: [
      "Sistemas de información geográfica con módulos de captura, consulta, monitoreo y seguimiento ambiental. Estructuración de la base de datos geográfica según los estándares definidos por la ANLA, facilitando la elaboración de los informes de cumplimiento ambiental ICA, estudios de impacto ambiental EIA, así como la consulta histórica de los datos."
    ]
  },
  {
    clase: 'gobierno',
    nombre: "Gobierno",
    descripcion: [
      "Sistemas de información gerenciales que consolidan diferentes fuentes de datos con capas geográficas propias de cada entidad. Validación de la infraestructura de SW y HW, cuadros de mando integral, visores temáticos, tableros de control, consulta de información geográfica histórica, reportes y estadísticas, que facilitan la toma de decisiones."
    ]
  },
]

export default dataSectoresPrincipales