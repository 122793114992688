import dataSectoresPrincipales from './../../data/dataSectoresPrincipales';

const Nosotros = () => {
  return (
    <>      
      <div className="pt-0 nosotros-home">
        <div className="container mx-auto">
          <div className="w-[80%] mx-auto text-center">
            <h3 className='titulo-somos'>Más de 20 años realizando proyectos SIG para empresas del sector minero, ambiental, gobierno e hidrocarburos.</h3>
            <p className="md:text-26">Somos una empresa colombiana fundada en el año 2001, dedicada a la <span className="texto-resaltado">consultoría y desarrollo de sistemas de información geográfica, con amplia experiencia en análisis, diseño, implementación, migración de datos, capacitación,</span> y puesta en producción de sistemas de Información geográfica, utilizando bases de datos.</p>
          </div>
          <div className="flex flex-wrap justify-end justify-center mt-10 md:mt-20 container-amarillo">
              {
                !!dataSectoresPrincipales && dataSectoresPrincipales.lenght !== 0 && dataSectoresPrincipales.map((servicio, index) =>
                  <div className="md:basis-1/2 lg:basis-3/12 flex sectores mb-10 lg:mb-16" key={`servicio${servicio.nombre}${index}`}>
                    <div className='basis-10/12 text-center mx-auto'>
                      <span className={`text-80 icon-${servicio.clase}`}></span>
                      <div className='mt-5'>
                        {!!servicio.nombre && <p className='mb-3 titulo text-20 opensans-700 uppercase'>{servicio.nombre}</p>}
                        <p className='descripcion opensans-300 text-17'>{servicio.descripcion}</p>
                      </div>
                    </div>
                  </div>
                )
              }              
            </div>
        </div>
        
      </div>
    </>
  )
}

export default Nosotros;